<template>
  <div>
    <h3>{{ this.isAddMode ? "Opret" : "Rediger" }} Funktions gruppe</h3>

    <vx-card no-shadow class="mt-8">
      <h2 v-if="!this.isAddMode" class="mb-6">Funktions gruppe for {{functionGroup.afdelingRolle}}</h2>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Afdeling</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="functionGroup.afdeling"
            placeholder="Afdeling navn..."
            :disabled="!this.isAddMode"
          />
        </div>
      </div>
      
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Afdelings rolle</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="functionGroup.afdelingRolle"
            placeholder="Afdeling rolle navn..."
            :disabled="!this.isAddMode"
          />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Bruger type</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="functionGroup.brugerType"
            placeholder="type..."
          />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Bruger status</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
            <vs-select
              placeholder="Vælg"
              v-model="functionGroup.brugerStatus"
              class="profile-prompt-select w-full"
              autocomplete>
              <vs-select-item
                v-for='(item, index) in [{ text: "Ingen", value: "" }
                ,{ text: "Fuld adgang", value: "Fuldadgang" }
                ]'
                :key="index"
                :value="item.value"
                :text="item.text"
              />
            </vs-select>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Har bogførring rettighederss</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <div class="center">
            <vs-radio
              v-model="functionGroup.bogforringRettighed"
              vs-name="radioBogforring"
              :vs-value="true"
              class="pr-8"
            >
              Ja
            </vs-radio>
            <vs-radio
              v-model="functionGroup.bogforringRettighed"
              vs-name="radioBogforring"
              :vs-value="false"
            >
              Nej
            </vs-radio>
            </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col sm:w-1/5 w-full">Funktioner</div>
        <div class="vx-col sm:w-2/5 w-full">
          <div class="center">
            <vs-textarea
              v-model="functionGroup.funktioner"
              class="w-full"
              placeholder="Funktions grupper"
            />
          </div>
        </div>
      </div>

    </vx-card>
    
    <div class="mt-4">
      <backbutton></backbutton>
      <vs-button
        @click="saveData"
        id="save-button"
        class="ml-4 mr-auto mt-2"
      >
        {{ this.isAddMode ? "Opret" : "Opdater" }}
      </vs-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    functionId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      query: "*",
      functionGroup: {
        afdeling: "",    
        afdelingRolle: "",
        brugerType: "",
        brugerStatus: "", 
        bogforringRettighed: null,
        funktioner: "",
      },

      radioBogforring : null
    };
  },
  computed: {
    isAddMode() {
      return !this.functionId;
    }
  },
  methods: {
    saveData: function() {
      this.$vs.loading();

      if (this.functionId) {
        this.updateFunctionGroup(this.functionGroup);
      } else {
        this.registerFunctionGroup(this.functionGroup);
      }
    },
    updateFunctionGroup(payload) {
      this.$http
        .put(process.env.VUE_APP_API + "/FunctionGroups/"+ this.functionId, payload)
        .then(resp => {
          if (resp.data.success === true) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Funktionsgruppe opdateret"
            });

            this.$router.push({ name: "functions" }).catch(() => {});
          } else {
            console.log(resp.data.error);

            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: "Funktionsgruppe kunne ikke opdateres: " + resp.data.error
            });
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    registerFunctionGroup(payload) {
      this.$http
        .post(process.env.VUE_APP_API + "/FunctionGroups", payload)
        .then(resp => {
          if (resp.data.success === true) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Funktions gruppe oprettet"
            });

            this.$router.push({ name: "functions" }).catch(() => {});
          } else {
            console.log(resp.data.error);

            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: "Funktions gruppe kunne ikke oprettes: " + resp.data.error
            });
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
  },
  created() {

    if (!this.isAddMode) {
      this.$vs.loading();

      this.$http
        .get(process.env.VUE_APP_API + "/FunctionGroups/" + this.functionId)
        .then(resp => {
          this.functionGroup = resp.data;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    }
    else{
      var copyId = this.$route.query.copyFromId
      if (copyId){
        this.$vs.loading();

        this.$http
          .get(process.env.VUE_APP_API + "/FunctionGroups/" + copyId)
          .then(resp => {
            this.functionGroup = resp.data;
            this.functionGroup.functionId = ""
            this.functionGroup.type = ""
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.$vs.loading.close();
          });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
select {
  height: 40px;
  padding: 10px;
  border-color: lightgrey;
  border-radius: 5px;
  color: #626262;
}
.horizontal-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.selector {
  width: 40% !important;
}
.selector select {
  width: 100% !important;
}
</style>
